<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>我的维修</h1>
      </el-header>
      <el-main class="scrollable-main">
        <!-- 添加筛选框 -->
        <el-form :inline="true" class="filter-form">
          <!-- 时间筛选框 -->
          <el-form-item label="时间">
            <el-date-picker
                v-model="startDate"
                type="date"
                format="yyyy-MM-dd"
                placeholder="选择起始日期"
            ></el-date-picker>
            <el-date-picker
                v-model="endDate"
                type="date"
                format="yyyy-MM-dd"
                placeholder="选择截止日期"
            ></el-date-picker>
          </el-form-item>

          <!-- 状态筛选框 -->
          <el-form-item label="状态">
            <el-select v-model="selectedState" clearable placeholder="请选择状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="已接单" value="已接单"></el-option>
              <el-option label="维修中" value="维修中"></el-option>
              <el-option label="暂停中" value="暂停中"></el-option>
              <el-option label="作业结束" value="作业结束"></el-option>
              <el-option label="工单关闭" value="工单关闭"></el-option>
            </el-select>
            <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
          </el-form-item>

          <!--分页框-->
          <div style="padding: 10px 0">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[5, 10, 20]"
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
          </div>
        </el-form>
        <template>
        <el-card v-for="item in records" :key="item.id" style="margin-bottom: 20px;">
          <div slot="header">
            <div>工单号：{{ item.reconum }}
              <el-tag :type="getTagType(item.state)" size="medium" style="margin-left: 20px;">{{ item.state }}</el-tag>
            </div>
          </div>
          <div>
            <div><span>接单人:{{ getHostUser(item.reconum) }}</span></div>
            <div><span v-if="getHelpUser(item.reconum)">助手:{{ getHelpUser(item.reconum) }}</span></div>
            <el-divider></el-divider>
            <div><span>工单类型:{{ item.type }}</span></div>
            <div><span>故障设备:{{ item.equipmentName }}</span></div>
            <div><span>故障描述:{{ item.description }}</span></div>
            <div><span v-if="getHelpUser(item.reconum)">我的工时：{{ getMyWorkHour(item.reconum) }}</span></div>
            <div><span>工单总工时：{{ formatTime(item.workHour) }}</span></div>
          </div>
          <el-divider></el-divider>
          <el-row type="flex" justify="center" style="margin-top: 10px;">
            <el-col class="text-center">
              <el-button type="primary" plain v-if="item.state === '维修中' || item.state === '暂停中' || item.state === '已接单'" @click="addMaintainer(item.reconum)">增加助手</el-button>
              <el-button type="primary" v-if="item.state !== '工单关闭'" plain @click="getComponent(item.id)">备件领用</el-button>
              <el-button type="primary" v-if="item.state === '作业结束' && !tooLate(item.endTime)" plain @click="cancelEnd(item.id)">撤销关单</el-button>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center" style="margin-top: 10px;">
            <el-col class="text-center">
<!--              <el-button type="success" plain v-if="!haveStarted(item.id) && item.state ==='已接单'" @click="getStart(item)" size="large">到达计时</el-button>
              <el-button type="success" plain v-if="haveStarted(item.id) && (getUserState(item.reconum) === '暂停中' || getUserState(item.reconum) === '已接单')" @click="ResumeTimer(item)" size="large">继续计时</el-button>-->
              <el-button type="success" plain v-if="getUserState(item.reconum) === '暂停中' || getUserState(item.reconum) === '已接单'" @click="ResumeTimer(item)" size="large">计时</el-button>
              <el-button type="success" plain v-if="getUserState(item.reconum) === '维修中'" @click="pauseTimer(item)" size="large">暂停</el-button>
                  <el-button @click="open(item.id)" style="margin-left: 10px;" v-if="getUserRole(item.reconum) === '接单人' && (getUserState(item.reconum) === '维修中' || getUserState(item.reconum) === '暂停中' || getUserState(item.reconum) === '已接单')" type="danger" plain size="large">结束</el-button>
              <el-button @click="endHelp(item.reconum)" style="margin-left: 10px;" v-if="getUserRole(item.reconum) === '助手' && (getUserState(item.reconum) === '维修中' || getUserState(item.reconum) === '暂停中' || getUserState(item.reconum) === '已接单')" type="danger" plain size="large">结束协助</el-button>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center" style="margin-top: 10px;">
            <el-col class="text-center">
              <el-button @click="goToDetail(item.id)">查看</el-button>
              <el-button plain v-if="item.state !== '作业结束' && item.state !== '工单关闭'" @click="download(item.equipmentId)">SOP</el-button>
              <el-button plain v-if="item.state === '维修中' || item.state === '暂停中' || item.state === '已接单'" @click="changeUser(item.id)">转单</el-button>
              <el-button v-if="item.state !== '工单关闭'" @click="addRecord(item.id,item.equipmentId,item.equipmentName)">派生工单</el-button>
              <el-button plain v-if="item.state === '作业结束'" @click="goChangePhoto(item.id)" size="small">重传完成图</el-button>
            </el-col>
          </el-row>
        </el-card>
        </template>
      </el-main>

      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <el-dialog title="领用备件" :visible.sync="dialogFormVisible" width="90%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="工单ID">
          <span style="font-size: 18px">{{form.id}}</span>
        </el-form-item>
        <el-form-item label="物料号" class="custom-form-item">
          <!--            <el-input placeholder="请输入物料号" v-model="form.compnum" @input="updateCompname"></el-input>-->
          <el-autocomplete
              class="inline-input"
              v-model="form.compnum"
              :fetch-suggestions="querySearch"
              placeholder="请输入物料号"
              :trigger-on-focus="false"
              @input="updateCompname"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="库位" class="custom-form-item">
          <!--            <span style="font-size: 18px">{{form.location}}</span>-->
          <!--            <el-input placeholder="请输入库位号" v-model="form.location" @input="updateCompname1"></el-input>-->
          <el-autocomplete
              class="inline-input"
              v-model="form.location"
              :fetch-suggestions="querySearch1"
              placeholder="请输入库位"
              :trigger-on-focus="false"
              @input="updateCompname1"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="零件名" class="custom-form-item">
          <span style="font-size: 18px">{{form.compname}}</span>
        </el-form-item>
<!--        <el-form-item label="单价" class="custom-form-item">-->
<!--          <span style="font-size: 18px">{{form.price}}元</span>-->
<!--        </el-form-item>-->
        <el-form-item label="数量">
          <el-input placeholder="请输入数量" v-model="form.sum"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="增加助手" :visible.sync="dialogFormVisible2" width="90%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="工单号">
          <el-input v-model="form6.reconum" disabled=""></el-input>
        </el-form-item>
<!--        <el-form-item label="维修工">-->
<!--          <el-select clearable v-model="form.userId" placeholder="请选择维修工" style="width: 100%">-->
<!--            <el-option v-for="item in maintainers" :key="item.id" :label="item.nickname" :value="item.id"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="维修工">
          <el-select v-model="form6.selectedUsers" multiple filterable placeholder="请选择">
            <el-option
                v-for="item in maintainers"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="save2">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="上传照片" :visible.sync="dialogFormVisible3" width="80%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="ID">
          <el-input v-model="form.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="工单ID">
          <el-input v-model="form.reconum" disabled></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
              class="avatar-uploader"
              action="http://39.106.92.106:9090/files/upload"
              :show-file-list="true"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeUpload"
              :multiple="true"
          >
            <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（可传多张）</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="save3(form.id)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="派生工单" :visible.sync="dialogFormVisible4" width="100%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="类型">
          <el-select clearable v-model="form.type" placeholder="请选择类别" style="width: 100%">
            <el-option label="维修自查" value="维修自查"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障类型" v-if="form.type">
          <el-select clearable v-model="form.failureType" placeholder="请选择故障类型" style="width: 100%">
            <el-option v-for="option in faultTypeOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接单人">
          <span>{{users.find(v => v.id === form.userId) ? users.find(v => v.id === form.userId).nickname : ''}}</span>
        </el-form-item>
        <el-form-item label="设备">
          <span>{{form.equipmentName}}</span>
        </el-form-item>
        <el-form-item label="设备ID" v-if="false">
          <span>{{form.equipmentId}}</span>
        </el-form-item>
        <el-form-item label="上级工单ID" v-if="false">
          <el-input v-model="form.uprecordId" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="save4">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="转移工单" :visible.sync="dialogFormVisible5" width="90%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="工单ID" v-if="false">
          <el-input v-model="form.id" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="维修工">
          <el-select clearable v-model="form.userId" placeholder="请选择维修工" style="width: 100%">
            <el-option v-for="item in maintainers" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible5 = false">取 消</el-button>
        <el-button type="primary" @click="save5">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="结束工单" :visible.sync="dialogFormVisible6" width="90%" >
      <el-form label-width="100px" size="small" ref="repairForm" :model="form" :rules="rules">
        <el-form-item label="工单ID" v-if="false">
          <el-input v-model="form.id" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="维修说明" prop="shuoming">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入维修内容"
              v-model="form.shuoming">
          </el-input>
        </el-form-item>
        <el-form-item label="完成图片">
          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="true">
            <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（可传多张）</span>
            <div>
              <span style="font-size: 12px">"尽量体现更换的备件或做过的处理"</span>
            </div>

          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible6 = false">取 消</el-button>
        <el-button type="primary" @click="endRecord">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="到达现场" :visible.sync="dialogFormVisible7" width="90%" >
      <el-form label-width="100px" size="small" ref="repairForm" :model="form" :rules="rules">
        <el-form-item label="到达图片">
          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess2" style="display: inline-block" :multiple="false">
            <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（一张）</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible7 = false">取 消</el-button>
        <el-button type="primary" @click="startRecord">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="暂停原因" :visible.sync="dialogFormVisible8" width="90%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="工单ID">
          <el-input v-model="form5.id" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="暂停原因">
          <el-select v-model="form5.pauseDescription" placeholder="请选择暂停原因">
            <el-option label="进行其他工单" value="进行其他工单"></el-option>
            <el-option label="缺备件" value="缺备件"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible8 = false">取 消</el-button>
        <el-button type="primary" @click="saveReason">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="重传完成图" :visible.sync="dialogFormVisible9" width="95%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="工单ID">
          <el-input v-model="form7.id" disabled autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="完成图片">
          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess3" style="display: inline-block" :multiple="true">
            <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（可传多张）</span>
            <div>
              <span style="font-size: 12px">"尽量体现更换的备件或做过的处理"</span>
            </div>

          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible9 = false">取 消</el-button>
        <el-button type="primary" @click="changePhoto">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "MyRepair",
  data() {
    return {
      form: {},
      form4: {},
      form2: {},
      form5: {},
      form6: {
        selectedUsers: [], // 存储选中的用户ID
        reconum: "",
      },
      form7: [],
      equipments: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      lines: [],
      users: [],
      maintainers: [],
      records: [],
      startDate : "",
      endDate : "",
      rmByreconum: [],
      fileUrls: [],
      selectedState: null,
      timer: "",
      content: "",
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      dialogFormVisible7: false,
      dialogFormVisible8: false,
      dialogFormVisible9: false,
      rules: {
        shuoming: [{ required: true, message: '请输入维修说明', trigger: 'change' }],
      },
      failureType: {
        '车间报修': [
          { label: '故障', value: '故障' },
          { label: '宕机', value: '宕机' },
        ],
        '维修自查': [
          { label: '预防性', value: '预防性' },
          { label: '故障', value: '故障' },
          { label: '修旧利废', value: '修旧利废' },
        ],
        '管理巡审': [
          { label: '预防性', value: '预防性' },
          { label: '故障', value: '故障' },
        ],
        '定期维修': [
          { label: '预防性', value: '预防性' },
        ],
        '修旧利废': []
      },
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    };
  },
  created() {
    this.load();

  },
  mounted() {
    this.fetchRecords();
    this.timer = setInterval(this.fetchRecords, 60000); // Fetch records every minute
  },
  computed: {
    faultTypeOptions() {
      return this.failureType[this.form.type] || [];
    },
    filteredRecords() {
      return this.getFilteredRecords();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取数据
    load() {
      // this.request.get("/recordMaintainer/myRepair/"+this.user.id).then(res => {
      //   this.records = res.data;
      // });
      this.request.get("/recordMaintainer/myRepair/page2/",{
        params:{
          id: this.user.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          selectedState: this.selectedState,
          startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
          endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
        }
      }).then(res => {
        this.records = res.data.records;
        this.records = this.records.filter(record => record.id != null)
        this.total = res.data.total
      });
      this.request.get("/recordMaintainer").then(res => {
        this.rmByreconum = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      this.request.get("/user/listMaintainer").then(res => {
        this.maintainers = res.data;
      });
    },

    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    loadComponent(){
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
    },
    handleDateRangeChange() {
      this.filteredRecords = this.getFilteredRecords();
    },
    handleStateChange() {
      this.filteredRecords = this.getFilteredRecords();
    },
    getFilteredRecords() {
      let filtered = this.records;

      // 过滤日期范围
      if (this.startDate) {
        filtered = filtered.filter(item => new Date(item.startTime) >= new Date(this.startDate));
      }
      if (this.endDate) {
        filtered = filtered.filter(item => new Date(item.startTime) <= new Date(this.endDate));
      }

      // 过滤状态
      if (this.selectedState) {
        filtered = filtered.filter(item => item.state === this.selectedState);
      }

      return filtered;
    },
    fetchRecords() {
      this.request.get("/recordMaintainer/myRepair/"+this.user.id).then(res => {
        this.records = res.data;
      });
    },
    getComponent(itemId) {
      this.loadComponent();
      this.dialogFormVisible = true
      this.form = {
        id: itemId,
      }
    },
    tooLate(endTime){
      // 获取当前时间的时间戳
      const now = new Date().getTime();

      // 将 endTime 转换为时间戳
      const endTimestamp = new Date(endTime).getTime();

      // 计算时间差，判断是否超过十分钟（600000毫秒）
      return (now - endTimestamp) > 600000; // 返回 true 或 false
    },
    addMaintainer(reconum) {
      this.dialogFormVisible2 = true
      this.form6 = {
        reconum: reconum,
      }
    },
    changeUser(recordId) {
      this.dialogFormVisible5 = true
      this.form = {
        id: recordId,
      }
    },
    haveStarted(id) {
      if (!this.records || this.records.length === 0) {
        return false;
      }

      return this.records.some(item => item.id === id && item.avatarUrl);
    },
    uploadFile(itemId,reconum) {
      this.dialogFormVisible3 = true
      this.form = {
        id: itemId,
        reconum: reconum,
      }
    },
    updateCompname() {
      const component = this.components.find(item => item.compnum === this.form.compnum);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.location = component ? component.location : '';
    },
    updateCompname1() {
      const component = this.components.find(item => item.location === this.form.location);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.compnum = component ? component.compnum : '';
    },
    querySearch(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter(queryString))
              .map(component => ({ value: component.compnum }))
          : [];
      cb(results);
    },
    createFilter(queryString) {
      return (component) => {
        return (component.compnum.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    querySearch1(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter1(queryString))
              .map(component => ({ value: component.location }))
          : [];
      cb(results);
    },
    createFilter1(queryString) {
      return (component) => {
        return (component.location.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      const formData = {
        sum: this.form.sum,
        compnum: this.form.compnum,
        recordId: this.form.id,
        price: this.form.price * this.form.sum,
        takeTime: formattedRecordTime,
      };
      if(this.form.compnum != null && this.form.sum != null){
        this.request.post("/recordComponent", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("保存成功")
            this.request.post("/component/updateSum", formData)
            this.dialogFormVisible = false
            this.loadComponent();
          } else {
            this.$message.error("保存失败")
          }
        })
      }else{
        this.$message.warning("备件号或数量为空")
      }
    },
    save2() {
      console.log("维修人员" + this.form6.selectedUsers);

      // 检查 this.form.userId 是否存在，存在才继续执行
      if (!this.form6.selectedUsers) {
        this.$message.warning("维修人员为空");
        return; // 退出方法，避免执行下面的代码
      }
      //
      // const formData = {
      //   userId: this.form.userId,
      //   reconum: this.form.reconum,
      //   state: "已接单",
      //   role: "助手"
      // };
      //
      // this.request.post("/recordMaintainer", formData).then(res => {
      //   if (res.code === '200') {
      //     this.$message.success("添加成功");
      //     this.dialogFormVisible2 = false;
      //     this.load();
      //   } else {
      //     this.$message.error("添加失败");
      //   }
      // });

      this.request.post("/recordMaintainer/handGroup", this.form6).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible2 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    save3(recordId) {
      // if(this.form.avatarUrl != null){
        // this.request.post("/failureRecord", this.form).then(res => {
        //   if (res.code === '200') {
        //     this.$message.success("保存成功")
        //     this.dialogFormVisible3 = false
        //     this.load()
        //   } else {
        //     this.$message.error("保存失败")
        //   }
      if(this.fileUrls != null){
        const requestData = {
          fileUrls: this.fileUrls,
          recordId: recordId
        };
        this.request.post("/recordFile/saveFile", requestData).then(res => {
              this.$message.success("上传成功")
              this.dialogFormVisible3 = false
              this.load()
        })
      }else{
        this.$message.warning("图片为空")
      }
    },
    save4() {
      if(this.form.type != null){
        this.request.post("/failureRecord", this.form).then(res => {
          if (res.code === '200') {
            this.$message.success("保存成功")
            this.dialogFormVisible4 = false
            this.load()
          } else {
            this.$message.error("保存失败")
          }
        })
      }else{
        this.$message.warning("类型为空")
      }
    },
    save5() {
      if(this.form.userId != null){
        const formData = {
          oldUserId: this.user.id,
          newUserId: this.form.userId,
          recordId: this.form.id,
        };
        this.request.post("/failureRecord/changeUser", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("转移成功")
            this.dialogFormVisible5 = false
            this.load()
          } else {
            this.$message.error("转移失败")
          }
        })
      }else{
        this.$message.warning("用户为空")
      }
    },
    async endRecord() {
      try {
        // 验证维修表单内容
        const valid = await new Promise((resolve) => {
          this.$refs.repairForm.validate((valid) => {
            resolve(valid);
          });
        });

        if (!valid) {
          this.$message.error("请填写维修内容");
          return;
        }

        // 处理图片上传逻辑
        if (this.fileUrls && this.fileUrls.length > 0) {
          const requestData = {
            fileUrls: this.fileUrls,
            recordId: this.form.id
          };
          const fileUploadRes = await this.request.post("/recordFile/saveFile", requestData);
          this.$message.success("图片上传成功");
        } else {
          this.$message.warning("图片为空");
          return; // 如果图片为空，直接返回，不再继续后续操作
        }



        // 处理结束维修的逻辑
        const recordId = this.form.id;
        const record = this.records.find(item => item.id === recordId);

        if (record) {
          const reportId = record.reportId;

          // 提交维修说明
          await this.request.post("/failureRecord/addshuoming", this.form);

          // 结束维修记录
          const endRecordRes = await this.request.post(`/failureRecord/endrecord/${recordId}/${this.user.id}`);

          if (endRecordRes.code === '200') {
            if (reportId) {
              // 结束维修报告
              await this.request.post(`/repairReport/endreport/${reportId}`);
            }
            this.$message.success("结束成功");
            this.dialogFormVisible6 = false;
            this.load(); // 重新加载数据
          } else {
            this.$message.error("结束失败");
          }
        } else {
          this.$message.error("未找到对应的维修记录");
        }
      } catch (error) {
        this.$message.error("操作失败，网络错误");
        console.error(error);
      }
    },
    endHelp(reconum){
      this.request.post("/recordMaintainer/endHelp/"+reconum + "/" + this.user.id).then(res => {
        if (res.code === '200') {
          this.$message.success("结束成功")
          this.dialogFormVisible6 = false
          this.load();
        } else {
          this.$message.error("结束失败")
        }
      })
    },
    goToHome() {
      this.$router.push('/mobile');
    },
    open(recordId) {
      this.dialogFormVisible6 = true
      this.form={
        id: recordId,
      }
    },
    formatTime(hours) {
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours % 24);
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    saveReason(){
      const formData = {
        id: this.form5.id,
        pauseDescription: this.form5.pauseDescription
      }
      console.log(formData)
      this.request.post("/failureRecord/saveReason", formData).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible8 = false
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    pauseTimer(item){
      this.form5.id = item.id
      this.dialogFormVisible8 = true;
      const userState = this.getUserState(item.reconum)
      if (item) {
        if (userState === '维修中') {
          this.request.post("/failureRecord/pause/" + item.id + "/" + this.user.id).then(res => {
            if (res.code === '200') {
              //item.state = '暂停中';
              this.request.post("/repairReport/pause/" + item.reportId)
              this.load();
              this.$message.success("暂停成功");
            } else {
              this.$message.error("暂停失败");
            }
          });
        }
      }
    },
    ResumeTimer(item) {
      const userState = this.getUserState(item.reconum)
      if (item) {
        if((userState === '暂停中' || userState === '已接单')){
          if(this.rmByreconum.filter(v => v.state === "维修中" && v.reconum !== item.reconum && v.userId === this.user.id).length >= 1){
            this.$message.warning("请暂停其他在计时的工单");
          }else{
            console.log(this.records.filter(v => v.state === "维修中").length)
            this.request.post("/failureRecord/continuerecord/" + item.id + "/" + this.user.id).then(res => {
              if (res.code === '200') {
                //item.state = '维修中';
                this.request.post("/repairReport/start/"+item.reportId)
                this.load();
                this.$message.success("继续成功");
              } else {
                this.$message.error("继续失败");
              }
            });
          }
        }
      }
    },
    goToDetail(recordId) {
      console.log('Clicked item id:', recordId);
      this.$router.push({ name: 'MRecordDetail', params: { id: recordId } });
    },
    cancelEnd(recordId){
      const formData = {
        userId: this.user.id,
        userName : this.users.find(u => u.id === this.user.id).nickname,
        operate: "撤销关单",
        information: "撤销作业结束: 工单ID:"+recordId
      }

      this.request.post("/failureRecord/cancelEnd/"+recordId).then(res => {
        if (res.code === '200') {
          this.load();
          this.request.post("/operateLog",formData)
          this.$message.success("撤销成功");

        } else {
          this.$message.error("撤销失败");
        }
    })
    },
    async startRecord() {
      try {
        const res = await this.request.post("/failureRecord", this.form4);

        if (res.code === '200') {
          const userState = this.getUserState(this.form4.reconum);
          console.log("pauseResumeTimer" + this.form4.id);

          if (userState === '已接单') {
            const continueRes = await this.request.post(`/failureRecord/continuerecord/${this.form4.id}/${this.user.id}`);

            if (continueRes.code === '200') {
              await this.request.post(`/repairReport/start/${this.form4.reportId}`);
              this.load();
              this.dialogFormVisible7 = false;
              this.$message.success("工单开始计时，注意LOTOTO");
            } else {
              this.$message.error("开始失败");
            }
          } else {
            this.$message.error("用户状态不正确");
          }
        } else {
          this.$message.error("保存失败");
        }
      } catch (error) {
        this.$message.error("请求失败，网络错误");
        console.error(error);
      }
    },
    goChangePhoto(recordId){
      this.form7.id = recordId
      this.dialogFormVisible9 = true
    },
    changePhoto(){
      const reportFileDTO = {
        id: this.form7.id,
        fileUrls: this.form7.fileUrls
      };
      this.request.post("/recordFile/changeFile", reportFileDTO).then(res => {
        if (res.code === '200') {
          this.$message.success("提交成功")
          this.dialogFormVisible9 = false
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const width = img.width;
            const height = img.height;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            // 开始以 0.9 的质量压缩图片，然后递归调整
            this.compressImage(canvas, file.type, 0.9, resolve, reject);
          };
        };

        reader.onerror = (error) => {
          console.error("图片读取失败：", error);
          reject(error);
        };
      });
    },

    compressImage(canvas, fileType, quality, resolve, reject) {
      canvas.toBlob((blob) => {
        if (blob.size <= 200 * 1024) {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        } else if (quality > 0.1) {
          this.compressImage(canvas, fileType, quality - 0.1, resolve, reject);
        } else {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        }
      }, fileType, quality);
    },

    handleAvatarSuccess(res) {
      if (!Array.isArray(this.fileUrls)) {
        this.fileUrls = [];
      }
      this.fileUrls.push(res.data);
    },
    handleAvatarSuccess2(res){
      this.form4.avatarUrl = res.data
    },
    handleAvatarSuccess3(res) {
      if (!Array.isArray(this.form7.fileUrls)) {
        this.form7.fileUrls = [];
      }
      this.form7.fileUrls.push(res.data);
    },
    addRecord(uprecordId,equipmentId,equipmentName){
      this.dialogFormVisible4 = true
      this.form = {
        userId: this.user.id,
        equipmentId: equipmentId,
        equipmentName: equipmentName,
        uprecordId: uprecordId,
      }
    },
    getTagType(state) {
      // 根据状态返回不同的标签类型（颜色）
      switch (state) {
        case '审批不通过':
          return 'danger';
        case '工单关闭':
          return 'success';
        case '作业结束':
          return 'info';
        case '维修中':
          return '';
        case '暂停中':
          return 'warning';
      }
    },
    download(equipmentId) {
      this.request.get("/equipment/"+equipmentId).then(res => {
        this.equipments = res.data;
        const url = this.equipments ? this.equipments.avatarUrl : '';
        if (url) {
          window.open(url);
        } else {
          this.$message.warning("无SOP文件");
        }
      });
    },
    getUserState(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      return filterdata.find(v => v.userId === this.user.id) ? filterdata.find(v => v.userId === this.user.id).state : '';
    },
    getType(type){
      return type ? type : '';
    },
    getHostUser(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const userName = filterdata.find(v => v.role === "接单人") ? filterdata.find(v => v.role === "接单人").userName : ''
      const state = filterdata.find(v => v.role === "接单人") ? filterdata.find(v => v.role === "接单人").state : ''
      return userName + "("+ state +")";
    },
    getHelpUser(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const recomt = filterdata.filter(item => item.role === "助手");
      return recomt.map(item => {
        const userName = item ? item.userName : ''
        const state = item ? item.state : ''
        const alldata = userName + "("+ state +")";
        return  alldata;
      }).join(', ');
    },
    getUserRole(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const userRm = filterdata.find(item => item.userId === this.user.id)
      return userRm ? userRm.role : ''
    },
    getMyWorkHour(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const workHour = filterdata.find(v => v.userId === this.user.id).workHour
      return this.formatTime(workHour);
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}
.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>